.mrc-partner-btn {
  @apply flex gap-4;

  .flex-cols {
    @apply flex flex-col gap-4 text-left text-sm tracking-wide sm:text-base;

    .flex-item {
      @apply flex items-center gap-1;
    }
  }

  .cta-btn {
    @apply w-full max-w-fit rounded-full bg-primary px-3 py-1.5 text-sm text-white hover:bg-primary-dark hover:text-white;
  }
}
